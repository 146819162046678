import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/SEO';

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <main className="page">
        <section className="contact-page">
          <article className="contact-info">
            <h3>Want To Get In Touch?</h3>
            <p>
              XOXO tbh semiotics 3 wolf moon echo park. Fam man braid before
              they sold out yuccie bushwick gentrify humblebrag crucifix banjo
              keffiyeh pug PBR&B. Truffaut jean shorts bushwick hell of palo
              santo gastropub whatever. Prism chambray lo-fi PBR&B kickstarter
              hammock adaptogen.
            </p>

            <p>
              Listicle activated charcoal cold-pressed franzen, actually 3 wolf
              moon quinoa taiyaki paleo. Lyft bespoke meggings chicharrones XOXO
              fam hammock pok pok cliche chillwave bushwick banjo trust fund.
              Pug you probably haven't heard of them trust fund bushwick iceland
              sustainable. Lomo typewriter pickled poutine fanny pack cronut
              raclette. Mixtape taiyaki chartreuse succulents trust fund
              coloring book.
            </p>
          </article>
          <article>
            <form
              className="form contact-form"
              action="https://formspree.io/f/xoqrerlg"
              method="POST"
            >
              <div className="form-row">
                <label htmlFor="name">Your Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="form-row">
                <label htmlFor="email">Your Email</label>
                <input type="text" name="email" id="email" />
              </div>
              <div className="form-row">
                <label htmlFor="message">Your Message</label>
                <textarea name="message" id="message"></textarea>
              </div>
              <button type="submit" className="btn submit">
                Submit Message
              </button>
            </form>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Contact
